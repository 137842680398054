.salary-tab{

    &__search-box{
        height: 50px;
        border-radius: 3px;
        border: solid 1px #d3dce6;
        background-color: #ffffff;
        cursor: pointer;
    };

    &__table--item{
        &-10{
            width: 10%;
        }
        &-60{
            width: 60%;
        }
    }
};

.border-red {
    border: solid 1px #ed553b;
    color: #ED553B;
}
