.worker-card-disclosure-header {
    padding: 1.25rem 0.7rem;
    width: 166px;
    min-width: 166px;
    cursor: pointer;
    border-right: 1px solid #e0e6ed;
    background-color: #f8f9fb;

    &__info {
        display: flex;
        align-items: center;
        padding: 0 1.5rem;
        justify-content: space-between;
        background-color: #ffffff;
        flex-grow: 1;

        &--closed {
            border-bottom-right-radius: 0.25rem;
        }

        &--mobile {
            display: none;
            padding: 0;
        }

        p {
            margin-bottom: 0 !important;
        }
    }

    &--closed {
        border-bottom-left-radius: 0.25rem;

        @include media-breakpoint-down(sm) {
            border-bottom-right-radius: 0.25rem;
        }
    }

    &__wrapper {
        width: auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &__icon {
        display: flex;
        justify-content: space-between;

        p {
            flex-grow: 1;
            text-align: center;
            margin-bottom: 0 !important;
        }

        &--closed {
            p {
                color: #8492a6;
            }

            svg {
                color: #8492a6;
            }
        }

        &--open {
            p {
                color: #14ca9c;
            }

            svg {
                color: #14ca9c;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid #e0e6ed;
        padding: 10px 15px;

        &__icon {
            p {
                font-size: 14px;
            }
        }

        &__info {
            display: none;

            &--mobile {
                display: block;
                padding: 26px 0;
            }
        }
    }
}
