.worker-rejection-button {
    color: #ef4444 !important;
    border-color: #ef4444 !important;
    width: 100%;
    margin-bottom: 1rem;

    &:focus {
        --tw-ring-color: #ef4444 !important;
    }
}
