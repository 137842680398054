.avatar-upload {
    background-color: #F1F3F7;
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    min-width: 20%;
}
.avatar-upload img {
    max-width: 90%;
    max-height: 90%;
    display: block;
    border-radius: 50%;
}