.worker-availability-table-header {
    background-color: white !important;
    border: none;
    z-index: 2;
    position: sticky;
    top: 0;

    &__date {
        box-shadow: inset 0 -1px #e5e7eb;
        text-transform: none !important;
        padding: 1rem 1.5rem !important;
        border-left: 1px solid #e5e7eb;
        max-width: 7rem;
        min-width: 7rem;
        position: sticky;
        z-index: 2;
        top: 0;
    }

    &__title {
        box-shadow: inset 0 -1px #e5e7eb;
        text-transform: none !important;
        padding: 1rem 1.5rem !important;
        color: #3d4858 !important;
        background: white;
        position: sticky;
        z-index: 12;
        left: 0;
        top: 0;
        min-width: 9.844rem;

        p {
            color: inherit;
        }
    }

    &__select {
        box-shadow: inset -1px -1px #e5e7eb;
        text-transform: none !important;
        padding: 1rem 1.5rem !important;
        color: #3d4858 !important;
        background: white;
        position: sticky;
        z-index: 14;
        top: 0;
        left: 9.844rem;
        min-width: 9.844rem;
        padding-left: 0 !important;
    }

    @include media-breakpoint-down(sm) {
        &__date {
            min-width: 4.5rem;
            max-width: 4.5rem;
        }

        &__select {
            display: none;
        }

        &__title {
            box-shadow: inset -1px -1px #e5e7eb;
        }
    }

    p {
        margin-bottom: 0 !important;
    }
}

.border-left-none {
    border-left: none !important;
}
