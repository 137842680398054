.toast-notification {
    position: fixed;
    bottom: 2.5rem;
    z-index: 1200;

    &--show {
        opacity: 0;
        animation: notification-show 0.5s linear 0.5s 1 normal forwards;
    }

    &--hide {
        opacity: 1;
        right: 1.5rem;
        animation: notification-hide 0.5s linear 0.5s 1 normal forwards;
    }
}

@keyframes notification-show {
    0% {
        opacity: 0;
        right: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        right: 1.5rem;
    }
}

@keyframes notification-hide {
    0% {
        right: 1.5rem;
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        right: 0;
    }
}
