.addNewWorker {

    &__search {
        height: 60px;
        border-radius: 3px;
        box-shadow: 0 1px 4px 0 #e0e6ed;
        background-color: #ffffff;
        padding-left: 0;
    }
}
