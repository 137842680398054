.worker-card-btn-group {
    border: 0;

    & > button {
        border-right: 0 !important;
    }

    &__trigger {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #3bbf9e !important;
        color: #ffffff !important;
        box-shadow: none !important;
    }
}
