.secondary-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1.2rem;

    input {
        background-color: white;
        border: 1px solid #8492a6;
    }
}
