.recommendation-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ffffff;
    min-height: 99px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 12px;

    &__image {
        margin-left: 20px;
        margin-top: 24px;
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        grid-gap: 0.5rem;
        width: 100%;
        margin-top: 24px;
        margin-left: 16px;
        margin-bottom: 10px;
    }

    &__title {
        margin-top: -5px;
        color: #8492a6;
    }

    &__text-red {
        color: #ed553b;
    }

    &__pcc-status-lg {
        margin-top: -14px;
        margin-left: 15px;
        &--title {
            font-size: 14px;
            line-height: 16px;
            color: #8492a6;
            word-break: break-word;
        }

        &--text {
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #3d4858;
            font-weight: 600;
            letter-spacing: 0.5px;
            word-break: break-word;
        }
    }

    &__status-container {
        display: none;
    }

    &__status-lg {
        margin-top: -14px;
        margin-left: 10px;
        &--title {
            font-size: 14px;
            line-height: 16px;
            color: #8492a6;
            word-break: break-word;
        }

        &--text {
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #3d4858;
            font-weight: 600;
            letter-spacing: 0.5px;
            word-break: break-word;
        }
    }

    &__status-md {
        display: none;
    }

    &__recommended-by {
        margin-top: -14px;
        margin-left: 10px;
        word-break: break-word;
    }

    &__recommended-to {
        margin-top: -14px;
        margin-left: 10px;
        word-break: break-word;
    }

    &__requested {
        margin-top: -14px;
        margin-left: 10px;
        word-break: break-word;
    }

    &__item-labels {
        font-size: 14px;
        font-size: 14px;
        line-height: 16px;
        color: #8492a6;
        word-break: break-word;
    }

    .recommended-name {
        margin-top: -5px;
    }

    .pill-margin {
        margin-left: -6px;
    }
}

.accepted-icon {
    margin-left: 0px;
}

@include media-breakpoint-down(md) {
    .recommendation-card {
        height: auto;

        &__three-dots {
            margin-right: 100px;
        }

        &__items {
            grid-template-columns: 1fr;
        }

        &__title {
            margin-top: 0px;
        }

        &__recommended-to {
            margin-top: 10px;
            margin-left: -2px;
        }

        &__recommended-by {
            margin-top: -10px;
            margin-left: -2px;
        }

        &__requested {
            margin-top: 0px;
            margin-left: -2px;
        }

        &__status-container {
            display: flex;
            flex-direction: row;
        }

        &__pcc-status-lg {
            display: none;
        }

        &__status-lg {
            display: none;
        }

        &__status-md {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            margin-right: 40px;
            &--title {
                font-size: 14px;
                line-height: 16px;
                color: #8492a6;
                margin-left: -20px;
            }

            &--text {
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                color: #3d4858;
                font-weight: 600;
                letter-spacing: 0.5px;
            }
        }

        &__items {
            margin-bottom: 16px;
        }

        .pill-margin {
            margin-left: -27px;
        }
    }

    .accepted-icon {
        margin-left: -22px;
    }
}

@include media-breakpoint-down(sm) {
    .recommendation-card {
        height: auto;

        &__three-dots {
            margin-right: 100px;
        }

        &__items {
            display: flex;
            flex-direction: column;
        }

        &__title {
            margin-top: 0px;
        }

        &__recommended-to {
            margin-top: 10px;
            margin-left: -2px;
        }

        &__recommended-by {
            margin-top: -10px;
            margin-left: -2px;
        }

        &__requested {
            margin-top: 0px;
            margin-left: -2px;
        }

        &__status-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 5px;
        }

        &__pcc-status-lg {
            display: none;
        }

        &__status-lg {
            display: none;
        }

        &__status-md {
            display: flex;
            flex-direction: column;
            margin-right: 10px;

            &--title {
                font-size: 14px;
                line-height: 16px;
                color: #8492a6;
            }

            &--text {
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                color: #3d4858;
                font-weight: 600;
                letter-spacing: 0.5px;
            }
        }

        &__items {
            margin-bottom: 16px;
        }

        .pill-margin {
            margin-left: -27px;
        }
    }

    .accepted-icon {
        margin-left: -22px;
    }
}
