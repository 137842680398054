.latest-applicants__container {
    max-height: 480px;
    margin-bottom: 2.5rem;
    overflow-y: scroll;
    overflow-x: auto;

    table {
        width: 100%;
        border: 1px solid #e5e7eb;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 1px 2px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        overflow: hidden;
    }

    .counties {
        display: flex;
        max-width: fit-content;
        min-width: 450px;
        flex-wrap: wrap;
        gap: 1rem;
    }
}
