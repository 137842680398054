.filter-search {
    height: 60px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 #e0e6ed;
    background-color: #ffffff;
    padding: 0;

    // core btn dropdown overwrites
    .btn-text-truncate {
        width: 100px;
        text-align: start;
    }

    .btn__lead {
        margin-right: 0.5rem;
    }

    .coredropdown__menu {
        min-width: 100%;
    }

    .coredropdown__menu__item {
        padding: 0.5rem 1rem;
        &[aria-selected="true"] {
            .dropdown-item-label{
                color: #14ca9c;
            }
        }
    }

    //aria-selected

    @include media-breakpoint-down(md) {
        padding: 0;
        height: auto;

        .coredropdown {
            width: 100%;
        }
    }

    &--sort {
        border-left: 1px solid #e0e6ed;
        height: 60px;
        padding: 0 0 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            width: 100%;
            border-left: none;
            border-top: none;
            border-bottom: 1px solid #e0e6ed;
            padding: 0;
            padding-left: 40px;
            &:not(.with-trail) {
                padding-left: 80px;
            }
            &.with-trail {
                .btn-text-truncate {
                    flex: 1 1;
                    padding-left: 2px;
                }
            }
            &.with-top-border {
                border-top: 1px solid #e0e6ed;
            }
        }
    }

    &--content {
        width: 300px;
    }
}

/*Overwrite for core style*/
.search-bar--company {
    @include media-breakpoint-down(md) {
        height: 60px;
    }
}
