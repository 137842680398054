.filters-wrapper {
    border-bottom: 1px solid #dee2e6;
}

@media only screen and (max-width: 1264px) {
    .filters-wrapper {
        flex-direction: column;
    }
    .margin-bottom-0 {
        margin-bottom: 0 !important;
    }

    .margin-top-0 {
        margin-top: 0 !important;
    }
}

nav:not(.menu-only) ~ .container-fluid {
    @media only screen and (max-width: 1579px) {
        .filters-wrapper {
            flex-direction: column;
        }

        .margin-bottom-0 {
            margin-bottom: 0 !important;
        }

        .margin-top-0 {
            margin-top: 0 !important;
        }
    }
}

.secondary-worker-filters {
    display: flex;
    flex-direction: column;

    .mobile-users {
        border-top: 1px solid #dee2e6 !important;
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .secondary-filters-stages {
        display: flex;
        flex-direction: row;
        align-items: center;

        .filter-by {
            width: auto;
            padding-bottom: 0 !important;
            margin-bottom: 1rem !important;
            border-radius: 4px;
            justify-content: space-between;
            // padding: 0.375rem 1rem;

            .filter {
                margin: 0;
                padding: 0.375rem 1rem;
            }
        }

        .filter-by:first-of-type {
            background: #d3dce6;
            margin-right: 0.5rem;
            border: 1px solid rgba(132, 146, 166, 0.5);
        }

        .filter-by:last-of-type {
            background: #dcf7f0;
            margin-right: 0.5rem;
            border: 1px solid rgba(20, 202, 156, 0.15);
        }
    }

    @media only screen and (max-width: 1290px) {
        .secondary-filters-stages {
            margin-left: 4px;
            margin-right: 4px;
            flex-direction: column;
            align-items: start;
        }
    }

    @media only screen and (max-width: 620px) {
        .secondary-filters-stages {
            .filter-by {
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 0px;
                width: 100%;
                align-items: start;

                .filter {
                    padding: 0.375rem 1rem;
                }
            }
        }
    }
}

nav:not(.menu-only) ~ .container-fluid {
    @media only screen and (max-width: 1530px) {
        .secondary-filters-stages {
            margin-left: 4px;
            margin-right: 4px;
            flex-direction: column;
            align-items: start;
        }
    }
}
