.worker-availability-filter {
    &__select {
        min-width: 218px;
        max-width: 218px;

        p {
            margin-bottom: 0 !important;
        }
    }

    //&__select-single {
    //    .pt-6{
    //        padding-top: 0;
    //    }
    //}

    &__date-picker {
        min-width: 316px;
    }

    &__select-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__date-picker-wrapper {
        width: auto;
    }

    &__type {
        display: none;
    }

    @media (max-width: 768px) {
        &__type {
            width: 100%;
            display: block;
        }
    }

    @media (max-width: 830px) {
        &__select-wrapper {
            width: 100%;
        }

        &__select {
            min-width: 100%;
            margin-bottom: 0.5rem;
        }

        &__date-picker {
            min-width: auto;
        }

        &__date-picker-wrapper {
            width: 100%;

            label {
                width: 100%;
            }
        }
    }
}

.react-datepicker {
    display: flex;
}
