.companies {
    padding: 0;

    &__filtered {
        margin-top: 36px;
        margin-bottom: 12px;

        &-count {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            color: #8492a6;
        }

        &-clear {

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #ed553b;
            margin-left: 7px;

            &:hover {
                color: #ed553b;
                @include fill-svg-animate-color(#ed553b);
            }
        }
    }

    &__table {

        td {
            padding: 0 0 !important;
        }

        &--image {
            min-width: 166px;
            width: 166px;
            max-width: 166px;
            height: 166px;
            border-radius: 3px 0 0 3px;
            margin-right: 24px;
        }

        &--title {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            color: #3d4858;
            // margin-top: 18px;
        }

        &--category {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #8492a6;
        }

        &--verified {
            font-size: 11px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #14ca9c;
            text-transform: uppercase;
            padding: 2px 10px;
            border-radius: 13px;
            margin-left: 8px;
            background-color: rgba(0, 0, 0, .1);
        }

        &--departaments {
            background-color: #f1f3f7;
            width: 224px;
            height: 32px;
            border-radius: 4px;
            // z-index: 1;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
        }

        &--info-title {
            height: 19px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #8492a6;
        }

        &--info {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3d4858;
            margin-top: 2px;
        }
    }
}
