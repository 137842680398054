.workersfilter {
    // ## Mixins
    @mixin row__padding {
        display: block;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    @mixin row__item {
        border-bottom: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
        padding-right: 24px;

        .form-control {
            padding-left: 12px;
        }

        .icon {
            margin-left: 7px;
            margin-right: 7px;
            height: 20px !important;
            width: 20px !important;
        }
    }

    // ## END Mixins

    // general resets
    input,
    input:focus {
        border: unset;
        outline: none !important;
    }

    .form-control {
        &:focus {
            box-shadow: unset;
        }
    }

    input#searchBar{
        height: 40px !important;
    }

    .card {
        .search-filter {
            @include row__padding();

            .left {
                flex-grow: 9;
            }

            .right {
                flex-grow: 1;

                .filterBtn {
                    .show-filters-btn__text,
                    .show-filters-btn__right-icon {
                        display: none;
                    }
                }
            }
        }

        .filters {
            margin: 0px 0px;
            border-top: 1px solid $gray-300;
            height: auto;

            .filters-dropdown-list__menu {
                box-shadow: $box-shadow;
                min-width: 100% !important;

                &:focus {
                    outline: none;
                }
            }

            &__inputs {
                input,
                input:focus {
                    border: unset;
                    outline: none !important;
                }

                padding-left: 12px;
                @include row__padding();
                @include row__item();
            }
        }
    }

    @include media-breakpoint-up(lg) {
        max-height: 100%;

        .card {
            .search-filter {
                @include row__padding();

                .left {
                    flex-grow: 9;
                }

                .right {
                    flex-grow: 1;

                    .filterBtn {
                        .show-filters-btn__text,
                        .show-filters-btn__right-icon {
                            display: block;
                        }
                    }
                }
            }

            .filters {
                margin: 0px 0px;
                border-top: 1px solid $gray-300;
                height: auto;

                &__inputs {
                    padding-left: 20px;
                    max-width: 250px;
                }
            }
        }
    }

    &__tags {
        padding-top: 16px;
        padding-left: 16px;
    }

    &__tag {
        display: inline-block;
        background-color: #8492a6;
        color: #fff;
        border-radius: 4px;
        line-height: 1;

        margin: 0 4px 4px 0;
        padding: 4px 0 8px 6px;

        span {
            font-size: 20px;
            padding-left: 8px;
            padding-right: 6px;
            cursor: pointer;
        }
    }

    .filter-menu {
        width: 20%;
        position: relative;
        user-select: none;
        cursor: pointer;

        .material-label {
            color: #3d4858;
        }

        .material-input {
            border: none;
            padding-right: 44px;
        }

        .custom-trigger {
            padding: 0 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 55px;
        }

        &__menu {
            width: max-content;
            max-width: 525px;
            min-width: 100%;
        }
    }

    .filter-location-input {
        user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        width: 20%;
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
    }

    .filter-dynamic-border {
        // clear input border
        border-right: solid 1px #d3dce6;
        border-bottom: solid 1px #d3dce6;
        &:nth-child(5nn + 5) {
            border-right: none;
        }
    }

    @include media-breakpoint-down(md) {
        .filter-location-input {
            width: 100%;
        }
        .filter-menu {
            width: 100%;
        }
        .filter-dynamic-border {
            border: none;
            border-bottom: solid 1px #d3dce6;
        }
    }
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.filter-list-spinner {
    top: unset !important;
    bottom: unset !important;
    align-items: flex-start !important;
    padding-top: 60px;
    background-color: rgb(241, 243, 247);
}
