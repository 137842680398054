@import 'left-fixed';

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    &.fixed-left {
        @include navbar-sidebar;
        width: $navbar-sidebar-width;
        transition: height 0.3s ease-in-out;

        &.menu-only {
            width: $navbar-menu-width;
        }

        @include media-breakpoint-up($navbar-sidebar-breakpoint) {
            right: auto;
            transition: width 0.3s ease-in-out;
            .navbar-nav {
                .nav-item {
                    .dropdown-toggle {
                        &:after {
                            border-top: $caret-width solid transparent;
                            border-left: $caret-width solid;
                            border-bottom: $caret-width solid transparent;
                            border-right: none;
                            vertical-align: baseline;
                        }
                    }
                }
            }
        }
    }
}

.navbar-container .content .navbar-nav .nav-item .nav-link .icon svg {
    height: unset;
    width: unset;
}

.dropdown-menu {
    max-height: 400px;
    height: auto;
    overflow-y: scroll;
}

.navbar-brand {
    display: flex;
    align-items: center;
}
