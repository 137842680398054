.worker-profile-activity {
    &__title {
        &--menus {
            display: flex;
            flex-direction: row;
            margin-bottom: -8px;
            // margin-right: -20px;
        }
    }
}

@include media-breakpoint-down(md) {
    .worker-profile-activity {
        &__title {
            &--menus {
                margin-top: 2rem;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .worker-profile-activity {
        &__title {
            &--menus {
                flex-direction: column;
                padding-left: 10px;
                align-self: flex-start;
            }
        }
    }
}

.worker-profile {
    svg {
        overflow: visible;
    }

    .assign_bmk_worker__btn-trigger {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #d3dce6;
    }

    .worker-profile-actions {
        .coredropdown__menu__item[aria-selected='true'] {
            .dropdown-item-label {
                color: #14ca9c;
            }
        }
    }

    #assign_bmk_worker_desktop {
        width: 215px;
    }
    .worker-activity-log {
        margin-top: -3px;
        .order-dropdown__controlls span {
            color: #8492a6 !important;
        }
    }
}
.worker-profile-dots {
    display: block !important;
    margin-left: 10px;
    margin-top: -10px !important;
}

.worker-profile__header {
    .hero-icon {
        width: 20px;
        height: 20px;
        color: #9da8b8;
    }
}
