.worker-card {
    box-shadow: 0 1px 4px 0 #e0e6ed;
    background-color: #ffffff;
    min-height: 166px;

    &__info {
        width: calc(50% - 200px);

        a {
            color: #000;
        }
    }

    &__options {
        width: 50%;
    }

    &__avatar {
        position: relative;
        background-color: #d3dce6;
    }

    &__avatar a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 166px;
        min-height: 166px;
        max-height: 200px;
        height: 100%;
    }

    &__avatar img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        image-orientation: from-image;
    }

    &__avatar svg {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    &__badges {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        object-fit: cover;
    }

    &__name {
        margin-bottom: 3.5rem;
    }

    .card__pill {
        border-radius: 4px;
        background-color: #f1f3f7;
        padding: 6px 8px;
    }

    &__play-video {
        all: unset;
        position: absolute;
        bottom: 0;
        left: 0;

        &:focus {
            outline: none;
        }
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        height: 100%;

        &__avatar a {
            width: 100%;
            height: 200px;
        }

        &__avatar img {
            max-width: 100%;
            width: 100%;
        }

        &__avatar svg {
            top: 35%;
            left: 45%;
        }

        &__info {
            width: 100%;
        }

        &__options {
            width: 100%;
        }
    }
}

.note-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #f1f3f7;

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &-content {
            font-weight: bold;
        }

        &-description {
            margin-bottom: 0.75rem;
            padding-right: 1rem;

            p {
                padding-bottom: 0.5rem !important;
            }
        }

        &-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            color: #8492a6;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 0.5rem;
            }

            svg {
                height: 1.1rem;
                width: 1.1rem;
            }

            &-date {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}

.note-card:last-child() {
    border: none;
    margin-bottom: 1rem;
}

.note-card__right-info-date:not(:last-child) {
    margin-right: 0.5rem;
    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }
}

.backgroud-gray {
    background-color: #f1f3f7;
}
