.profile-uploads {
    h5 {
        font-size: 18px;
    }
    &__uploads {
        &__box {
            flex: 1;
        }
        &__photo-overlay {
            width: 100%;
            img {
                width: 100%;
            }
        }
        &__video-overlay {
            width: 100%;
            height: 100%;

            &__player {
                width: 100%;
                height: 85%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__uploads {
            &__box.profile-uploads__uploads__box--margin-top {
                width: 100%;
                margin-top: 48px;
            }
        }
    }
}
