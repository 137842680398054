.attachmentssettingslistitem {
    border: 0px solid rgba(0, 0, 0, 0.125);
    padding: 8px 6px;
    border-bottom: 1px solid $gray-300;

    @include hover-background($gray-100);
    &:hover {
        cursor: default !important;
    }

    /* Card Style */
    background-color: #fff;
    background-clip: border-box;
    border-radius: $border-radius;

    /* Radius */
    td:first-child, th:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius:  $border-radius;
    }
    td:last-child, th:last-child {
        border-bottom-right-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    &__buttons {
        height: 100%;
        padding-top: 16px !important;
        &__btn {
            margin: 0px;
            margin-left: 18px;
        }
    }
}
