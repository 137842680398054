.width-2 {
    width: 2rem;
}

.border-grey-row{
    padding: 8px 6px;
    border: 0 solid rgba(0,0,0,.125);
    border-bottom: 1px solid #dee2e6;
    -webkit-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
}

.txt-14 {
    font-size: 14px;
}
.txt-black {
    color: #3D4858;
}

.c-pointer{
    cursor: pointer;
}
