.ods-introductionConversation-badge {
    color: #8b5cf6 !important;
    background-color: #ede9fe !important;
    border: 1px solid #ddd6fe;
    margin: 4px;
}

.ods-agreement-badge {
    color: #0EA5E9 !important;
    background-color: #F0F9FF !important;
    border: 1px solid #D0EDFC;
    margin: 4px;
}

.ods-nda-badge {
    color: #F97316 !important;
    background-color: #FFF7ED !important;
    border: 1px solid #FEE4CF;
    margin: 4px;
}
