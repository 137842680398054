.worker-recommendation-card {
    display: flex;
    align-items: center;
    max-width: 100%;

    .avatar {
        &-ring {
            border: 1px solid #d3dce6;
            border-radius: 100%;
            padding: 3px;

            &--idle {
                opacity: 0.5;
            }

            &--accepted {
                border-color: #3bbf9e;
            }
        }
    }

    &__content {
        margin-left: 0.9rem;

        p {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}
