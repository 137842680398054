.administration-skill__item {
    padding: 1.5rem;
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid #dee2e6;
    background-clip: border-box;
    border-radius: 0.25rem;


    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &--text-container {
        margin-right: 10px;
        @include media-breakpoint-down(md) {
            text-align: center;
            margin-right: 0;
        }
    }


    &--button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
       

        @include media-breakpoint-down(md) {
            margin-top: 10px;
            
        }

        .btn{
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            font-weight: 200;

            span{
                margin-left: 0.5rem;
                font-weight: 200;
                font-size: 14px;
            }

            &:hover {
                color: $green;
                transition: all 0.2s ease-in-out;
        
                border-color:$green;
        
                .btn-icon{
                    transition: all 0.2s ease-in-out;
                    transform: translate(-3px);
                }

                @include fill-svg-animate-color($green);
            }
        }
    }
}
