.gcclistheader {
    .gcclistitem {
        background-color: darken($gray-200, 3%);
        border-bottom: none;

        &:hover {
            background-color: darken($gray-200, 3%);
        }

        th {
            padding: 8px 0.75rem;
            font-size: 14px;
            line-height: 14px;
        }
    }

    &__separator {
        padding: 6px !important;
    }
}

.gcclistitem {
    border: 0px solid rgba(0, 0, 0, 0.125);
    padding: 8px 6px;
    border-bottom: 1px solid $gray-300;

    @include hover-background($gray-100);
    &:hover {
        cursor: default !important;
    }

    /* Card Style */
    background-color: #fff;
    background-clip: border-box;
    border-radius: $border-radius;

    &__button {
        flex: 1;
        &__item {
            padding: 0;
            margin: 0;
        }
    }

    /* Radius */
    td:first-child, th:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius:  $border-radius;
    }
    td:last-child, th:last-child {
        border-bottom-right-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }
}

