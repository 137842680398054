.worker-card-stage-details {
    display: flex;
    align-items: center;

    &--dot {
        width: 0.625rem;
        height: 0.625rem;
        background-color: #3bbf9e;
        border-radius: 9999px;
        margin-right: 0.625rem;
    }

    &--line {
        height: 1.15rem;
        width: 1px;
        border-right: 1px solid gray;
        margin: 0 0.5rem;
    }

    @include media-breakpoint-down(md) {
        padding-right: 1rem;
    }
}
