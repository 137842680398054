.worker-recommendation-panel {
    margin-bottom: 2rem;
    border-radius: 3px !important;
    padding: 0 1rem;

    h5,
    p:not(p:last-child) {
        font-weight: 600;
        color: #3d4858;
    }

    .worker-recommendation-alert {
        max-width: 420px;
        h3 {
            margin-bottom: 0 !important;
        }
    }

    .worker-recommendation-actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;

        div:first-child {
            flex-grow: 1;
        }
    }
}
