.switch-departament {

    .modal-dialog{
        max-width: 1000px !important;
        min-width: 300px !important;
    }

    &__item {
        border-bottom: solid 1px #F8F9FB;
        padding: 20px 0;

        &-name {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            color: #647284;
            margin: 0;
            padding: 0;
            margin-left: 20px;
            width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden
        }

        &-category {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #8f9daf;
            margin: 0;
            padding: 0;
            margin-left: 20px;
        }

        &-place {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9aa7b8;
            margin: 0;
            padding: 0;

            width: 100px;
            margin-left: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &-dark {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #465263;
                margin: 0;
                padding: 0;
                width: 100px;
                margin-left: 20px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
