.dashboard-content-wrapper {
    margin-top: 4.75rem;
}

.dashboard__stats {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    background-color: #fff;
    box-shadow: 0 0.3rem 0.6rem rgba(52, 58, 64, 0.075);
    border-radius: 0.25rem;
    overflow: hidden;

    &--header {
        border-bottom: 1px solid #dee2e6;
    }

    .content-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding-bottom: 50px;
        padding-top: 38px;

        h5 {
            font-weight: 100;
            font-size: 18px;
        }

        .separator {
            width: 50px;
            height: 2px;
            background-color: #14ca9c;
            margin-top: 20px;
            margin-bottom: 25px;
        }

        span {
            font-weight: 400;
            font-size: 42px;
        }
    }

    @include media-breakpoint-down(sm) {
        .content-row {
            margin-top: 0;
        }

        .content-item {
            margin-top: 1.5rem;

            h5 {
                border-bottom: 1px solid #14ca9c;
                width: 20rem;
                padding-bottom: 2rem;
            }
        }

        .content-item:last-child {
            h5 {
                padding-bottom: 1rem;
            }
        }
    }
}

.placeholder-loader {
    overflow: hidden;
    background: #000;
    position: relative;
    height: 3rem;
    width: 6rem;

    // Animation
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8; // Fallback
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1300px; // Animation Area
}

.right-border {
    border-right: 1px solid #e0e6ed;
}

.top-border {
    border-top: 1px solid #e0e6ed;
}

.left-border {
    border-left: 1px solid #e0e6ed;
}

.dashboard-section {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 1.2rem;
    margin-top: 1.5rem;
    margin-left: -10px;
    margin-right: 20px;
    justify-content: space-between;
}

.dashboard-heading {
    color: #3d4858 !important;
    font-weight: 500;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 1024px) {
    .dashboard-section {
        display: grid;
        grid-template-columns: 100%;
    }
}
@media only screen and (max-width: 1400px) {
    .dashboard-section {
        display: grid;
        grid-template-columns: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .dashboard-section {
        display: grid;
        grid-template-columns: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
}

.lastseen {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__item {
        background-color: #ffffff;
        box-shadow: 0px 1px 4px #e0e6ed;
        border-radius: 3px;
        margin-bottom: 16px;

        &--title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #3d4858;
            padding-left: 24px;
            padding-top: 24px;
            padding-bottom: 10px;
        }

        &--content {
            display: flex;
            flex-direction: row;
            padding-left: 24px;
            padding-bottom: 24px;
            align-items: center;
        }

        &--stats-number {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #3d4858;
            padding-left: 10px;
        }
    }
}
