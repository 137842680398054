.profilepage__body {
    &__tabs {
        .tabs__header {
            border-bottom: unset;

            ul {
                margin: 0px;

                span {
                    margin-right: 0px;
                }

                li {
                    margin: 0xp;
                    padding: 15px 24px;
                    font-size: 16px;
                    background-color: none;

                    &.active {
                        border-bottom: unset;
                        background-color: white;
                        border-top-right-radius: $border-radius;
                        border-top-left-radius: $border-radius;
                    }
                }

                @include media-breakpoint-down(md) {
                    display: flex;
                    flex-direction: column;
                    border: 1px solid white;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
            }
        }
    }
}

.profilepage__sidebar {
    .full-address-box {
        margin-top: 0.5rem !important;
        padding-left: 0 !important;
    }
}

.preview-item {
    &__border-top {
        margin-bottom: 0.75rem;
        margin-top: 0.75rem;
        border-top: 1px solid #f1f3f7;
    }

    &__header {
        font-size: 16px;
        color: #3d4858;
    }

    &__description {
        margin-top: 8px;
    }
}

.preview-container {
    margin-top: 1.5rem;
}
