@mixin navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindex-fixed;

    .navbar-container {
        .navbar-collapse {
            .navbar-nav {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-up($navbar-sidebar-breakpoint) {
        bottom: 0;
        flex-flow: row nowrap;
        align-items: flex-start;

        .navbar-container {
            flex-flow: column nowrap;
            align-items: flex-start;

            .navbar-collapse {
                flex-grow: 0;
                flex-direction: column;
                width: 100%;

                .navbar-nav {
                    flex-direction: column;
                    width: 100% !important;
                    .nav-item {
                        width: 100%;
                        .dropdown-menu {
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}
