.confirm-ok-modal {
    p {
        margin: 1rem 0;
    }
    ul {
        margin: 1rem 0;
        padding-left: 2rem;
        list-style-type: none;
        li {
            list-style-type: disc;
            padding-left: 0.5rem;
        }
    }
}
