.worker-rejection {
    &__description {
        div {
            height: 18.75rem;

            textarea {
                height: 100% !important;
            }
        }
    }

    &__radio {
        margin-top: 2rem;
    }
}
