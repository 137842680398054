.worker-card-disclosure-panel {
    display: flex;
    align-items: stretch;
    background-color: #f8f9fb;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    &__content {
        width: 100%;
        padding: 2rem 0;

        & > div:nth-child(n + 3) {
            padding-top: 2rem;
            div {
                padding-bottom: 1.25rem;
            }
        }
    }

    &--spacing {
        width: 166px;
        min-width: 166px;
    }
    &__note {
        padding-top: 0 !important;
        div {
            padding-bottom: 0 !important;
        }
    }

    &__info--mobile {
        display: none;
        padding: 0;
    }

    &__cv--mobile {
        display: none;
    }

    p {
        margin-bottom: 0 !important;
    }

    @include media-breakpoint-down(md) {
        &__content {
            & > div:nth-child(n + 3) {
                padding-top: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &--spacing {
            display: none;
        }

        &__content {
            padding: 0 1rem;

            & > div:nth-child(n + 3) {
                padding-top: 0;
            }
        }

        &__worker-details {
            & > div {
                padding-top: 0.5rem;
            }
        }

        &__info--mobile {
            display: block;
            padding: 14px 0;
        }

        &__cv--mobile {
            display: block;
            padding-bottom: 1.5rem;
        }
    }
}
