.emails-item {
    padding: 1rem;
    width: 100%;
    background-color: $white;
    border: 0 solid rgba(0, 0, 0, 0.125);
    background-clip: border-box;
    font-size: 14px;
    word-break: break-all;
    height: auto;
    border-bottom: 1px solid #dee2e6;
    text-decoration: none;
    color: black;
    min-height: 128px;

    &:hover, &:active  {
        text-decoration: none;
        color: black;
    }

    @include box-shadow();
    @include hover-background($gray-100);

    &:first-child() {
        border-radius: 0.25rem;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    }

    &:last-child() {
        border-bottom: none;
    }

    @include media-breakpoint-down(lg) {
        text-align: center;
        font-size: 16px;

        span {
            margin: 0.3rem;
        }
    }
}

.emails-header {
    width: 100%;
    padding: 0.4rem 1rem;
    background-color: #e7ebf1;
    height: 2rem;
    margin-bottom: 0.8rem;
    border-radius: 3px;
    font-size: 14px;

    // remove header on screens smaller then 1180px
    @include media-breakpoint-down(lg) {
        display: none;
        visibility: hidden;
        pointer-events: none;
    }
}

.single-email-container{
    width: 100%;
    background-color: $white;
    border: 1px solid #dee2e6;
    word-break: break-all;
    @include box-shadow();

    &--separator{
        height: 1px;
        border-bottom:  1px solid #dee2e6;
    }
}
