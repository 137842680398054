.neworderpage {
    padding-bottom: 10vh;

    &__newshift-btn {
        color: $green !important;

        @include fill-svg-color($green);
    }

    &__select-title {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #8492a6;
    }

    &__textbox-widget {
        display: grid;
        width: 100%;
        grid-template-areas: "textbox widget";
        grid-template-columns: 2fr 1fr;
        grid-gap: 18px;

        .WYSIWYG {
            height: calc(100% - 170px);
        }
    }

    &__textbox-only {
        display: grid;
        width: 100%;
        grid-template-areas: "textbox";
        grid-template-columns: 1fr;
    }

    &_textbox {
        grid-area: textbox;
    }

    &_textbox-widget {
        grid-area: widget;
        margin-top: 3rem;

        h5 {
            margin: 0 !important;
        }

        .department-patient-widget {
            border: 1px solid #d3dce6;
            box-sizing: border-box;
            border-radius: 6px;
            margin-top: 3.4rem;

            &__tags__tag {
                background: #fff;
            }
        }

        .department-patient-widget--empty {
            margin-top: 3.4rem;
        }
    }

    .custom-table {
        tr td {
            border-bottom: 1px solid $gray-300;

            span {
                margin-top: auto;
                margin-bottom: auto;

                .icon {
                    margin-top: 3px;
                }
            }
        }

        .no-borders {
            td {
                border-bottom: unset !important;
            }
        }
    }

    &__avatarholder {
        pointer-events: all;
    }

    &__dropselect {
        //d-flex flex-row align-items-center
        .coredropdown__menu {
            min-width: 200px;
            left: 0;
            right: unset;
            margin-top: 2px;
        }
        &__trigger {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 0;
        }

        &__trigger:active,
        &__trigger:focus {
            outline: 0 !important;
            box-shadow: none !important;
        }
    }
}

.filter-button {
    margin-top: -13px;
}

.filter-button-clear {
    margin-top: -13px;
    color: red;

    svg * {
        fill: red;
    }
}

.filter-button-clear:hover {
    color: red;

    svg * {
        fill: red;
    }
}

.no-select {
    user-select: none;
}

.pointer-cursor {
    cursor: pointer;
}

.shift-filled-plus-minus:hover {
    svg * {
        fill: #14ca9c;
    }
}

.autocomplete_order_dropdown {
    .coredropdown__menu {
        max-width: 100%;
    }
}
