.custum-select {
    width: 100%;
    // white-space: nowrap;

    .custom-select-container {
        padding-right: 3px;
        padding-left: 3px;

        .custom-select__control {
            border-radius: 3px;
            border: transparent !important;
            // background-color: #ffffff;

            &--is-focused {
                border: transparent !important;
                box-shadow: none !important;
            }
        }

        .custom-select__menu {
            box-shadow: none;
            width: 100%;

            .custom-select__menu-list {
                padding-bottom: 0;
                border-radius: 3px;
                border: solid 1px #d3dce6;
                background-color: #ffffff;
                padding-top: 0;
                // min-width: 500px;
                // max-width: 500px;

                .custom-select__option--is-focused {
                    font-size: 16px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    letter-spacing: normal;
                    color: #3d4858;
                    background-color: #e0e6ed;
                }

                .custom-select__option--is-selected {
                    background-color: #e0e6ed;
                    color: #3d4858;
                }

                &::-webkit-scrollbar {
                    display: none; // Safari and Chrome
                }
            }
        }

        .custom-select__indicator {
            // margin-top: -20px;

            &-separator {
                display: none !important;
            }
        }

        // .custom-select__dropdown-indicator {
        // margin-top: -20px;

        //   }

        &:hover {
            cursor: pointer !important;
        }
    }
}


