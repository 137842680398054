// 1. Core
@import 'react-tooltip/dist/react-tooltip.css';
@import 'tailwind-reset';
@import 'tailwind.css';
@import 'uwork-app-core/dist/styles/main.scss';

// 2. Pages
@import 'pages/index';

// 3. Components
@import 'components/index';

// 4. Containers
@import 'containers/index';

// 5. OverWrites
@import 'overwrites/index';

// 6. Atoms
@import '../components/Atoms/index';

// 6. Molecules
@import '../components/Molecules/index';

// 6. Organisms
@import '../components/Organisms/index';

body {
    /* primary colors */
    --color-primary-100: 230, 251, 245;
    --color-primary-200: 195, 250, 234;
    --color-primary-300: 59, 191, 158;
    --color-primary-400: 59, 191, 158;
    --color-primary-500: 59, 191, 158;
    --color-primary-600: 59, 191, 158;
    --color-primary-700: 42, 136, 113;
    --color-primary-800: 42, 136, 113;
    --color-primary-900: 42, 136, 113;
}
