.border-1 {
    border-width: 1px;
}

.min-w-1024 {
    min-width: 1024px;
}

@media (min-width: 1024px) {
    .lg\:min-w-full {
        min-width: 100%;
    }
}

.z-1111 {
    z-index: 1111;
}
