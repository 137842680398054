.personal-information {
    &__body {
        &__zip {
            &__state {
                margin-top: 7px;
                font-size: 12px;
            }
        }
    }
}
