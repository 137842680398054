.worker-intro-conversation {
    margin-bottom: 2rem;
    border-radius: 3px !important;

    &__panel {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        overflow-y: auto;
        max-height: 368px;
    }

    &__body {
        h1 {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 600;
            color: #3d4858;
            padding: 0.25rem 0;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 600;
            color: #3d4858;
            padding: 0.25rem 0;
        }

        p {
            padding-bottom: 0.15rem 0 !important;
        }

        ul {
            padding: 0.15rem 0 0.15rem 1.2rem;
            list-style-type: circle;
        }

        ol {
            padding: 0.15rem 0 0.15rem 1.4rem;
            list-style-type: decimal;
        }
    }
}
