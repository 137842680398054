.worker-calendar {
    width: 100%;
    min-height: 768px;

    &__helptext {
        display: block;
        margin-top: 1.5rem;
    }

    &__wrapper {
        width: 100%;
        margin: 40px 15px 0 15px;
    }

    .react-datepicker__week {
        min-height: 88px;
    }

    @include media-breakpoint-down(md) {
        height: 0;

        &__helptext {
            display: none;
        }

        .react-datepicker__week {
            min-height: auto;
        }
    }

    &--booked {
        background-color: #fecaca;

        &:hover {
            background-color: #fee2e2;
        }
    }

    &--in-shift {
        background-color: #fdba74;

        &:hover {
            background-color: #ffdbb5;
        }
    }

    .react-datepicker {
        display: block;
    }
}
