.order {
    min-height: 20vh;

    &__header-card {
        padding: 22px;

        @include box-shadow ();

        &__avatar {
            position: relative;
            max-height: 46px;
            height: 46px;
            max-width: 46px;
            width: 46px;
            margin-right: 20px;

            @include media-breakpoint-down(md) {
                align-self: center;
            }
        }


        &__user {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                align-self: center;
                margin-bottom: 1rem;
            }

            @include media-breakpoint-down(sm) {
                align-items: center;
            }


            &-fullName {
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.22;
                letter-spacing: normal;
                color: #3d4858;
                margin: 0;
                text-align: left;
            }

            &-worker {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #8492a6;
                margin: 0;
                text-align: left;
            }
        }

        &__description {
            h5 {
                margin: 0;
                padding: 0;
                font-size: 18px;
            }
        }

        &__button {
            flex-grow: 1;
            text-align: right;

            span {
                cursor: pointer;
                font-size: 14px;
                padding: 10px 22px;
            }

            .icon {
                margin-right: 10px;
            }

            @include media-breakpoint-down(md) {
                align-self: center;
            }

            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .btn{
                    height: 2.5rem;
                    width: 100%;
                    margin: 0;
                }
            }
        }

        &__list {
            border-top: 1px solid $gray-200;
            padding-top: 16px;
            margin-top: 16px;
            text-align: left;

            &__item {
                display: inline-block;
                background: $gray-400;
                padding: 2px 4px;
                margin-right: 6px;
                margin-bottom: 6px;
                border-radius: 3px;
                font-size: 0.875rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__body-card {
        width: 100%;
        padding: 22px;
        @include box-shadow ();

        &__header {
            border-bottom: 1px solid $gray-300;
            padding-bottom: 21px;
            margin-bottom: 16px;

            h5 {
                margin: 0;
                padding: 0;
                font-size: 18px;
            }

            .icon {
                margin-right: 10px;
            }

            &__title {
                flex-grow: 1;
            }

            &__location {
                margin-right: 24px;
            }
        }
    }

    &__description {
        margin-bottom: 32px;

        p {
            font-size: 16px;
        }
    }

    &__body {
        table {
            thead {
                background-color: $gray-200;
                border-radius: $border-radius;
                -moz-border-radius: $border-radius;
                -webkit-border-radius: $border-radius;
                height: 35px;
                font-size: 14px !important;

                th {
                    padding: 8px 0.75rem;
                }
            }

            .first-col {
                width: 40%;
            }

            tbody {
                tr {
                    border-bottom: 1px solid $gray-200;
                }
            }
        }
    }

    .pageLinks {
        display: flex;
        flex-direction: row;
        margin: 0;
        list-style: none;
        justify-content: space-between;

        &__item {
            margin-left: 4px;
            margin-right: 24px;
            cursor: pointer;
        }

        &__item--link{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__item--link svg {
            width: 16px;
            height: 16px;
        }

        a {
            color: #3C4857;
        }

        @include media-breakpoint-down(sm){
            padding: 0;

            &__item--link{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 10px;
            }
        }
    }

    .header__title {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    @include media-breakpoint-down(md) {
        &__header-card {
            text-align: center;

            &__avatar {
                max-height: 56px;
                height: 56px;
                max-width: 56px;
                width: 56px;
                margin-right: 0;
                margin-bottom: 20px;
            }

            &__description {
                margin-bottom: 20px;
            }
        }

        &__body-card {
            &__header {
                align-items: flex-start;
                border-bottom: none;
                margin-bottom: 0;

                &__title {
                    margin-bottom: 20px;
                }

                &__location {
                    margin-bottom: 12px;
                }
            }
        }

        &__body {
            table {
                width: 140% !important;
            }
        }
    }
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}
