.modalAddNewCompany {
    &__step {
        font-size: 14px;
        color: #8492a6;
    }

    &__table {
        background-color: #f1f3f7;
        border-radius: 3px;
        color: #647284;

        th {
            font-size: 12px;
            font-weight: normal;
        }

        th:last-child {
            text-align: right;
        }

        td {
            border-top: solid 1px #e0e6ed;
            padding: 16px 12px;
        }

        tr:first-child {
            border-top: 1px solid #e0e6ed;
        }

        td:last-child {
            text-align: right;
        }
    }

}
