.worker-card {
    width: 100%;
    height: fit-content;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-color: #ffffff;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e0e6ed;

    &__wrapper {
        width: 100%;
        margin-bottom: 1rem;
        padding: 0;
        display: flex;
        flex-direction: row;
        border-radius: 0.25rem;
        box-shadow: 0px 1px 4px #e0e6ed;
    }

    &__row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__content {
        width: 100%;
        min-width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.25rem 1.5rem;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        border-bottom: 0;
        border-top-left-radius: 0.25rem;

        &__content {
            min-width: auto;
        }
    }

    .hero-icon {
        width: 20px;
        height: 20px;
        color: #9da8b8;
    }

    p {
        margin-bottom: 0 !important;
    }

    .worker-profile-dots {
        margin-top: 6px !important;
        margin-left: 0 !important;

        @include media-breakpoint-down(sm) {
            & > div {
                & > div {
                    width: auto;
                }
            }
        }
    }
}
