.worker-agreements__container {
    margin-bottom: 2.5rem;
    overflow-y: scroll;
    overflow-x: auto;

    table {
        width: 100%;
        border: 1px solid #e5e7eb;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 1px 2px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
    }
}
