.metrics-filter {
    @media screen and (max-width: 830px) {
        width: 100%;
        flex-direction: column;

        & > div,
        & > button {
            width: 100%;
        }
    }

    &__selectMenu {
        min-width: 218px;
        max-width: 218px;

        p {
            margin-bottom: 0 !important;
        }
    }

    &__date-picker {
        min-width: 316px;
    }

    &__select-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__date-picker-wrapper {
        width: auto;
    }

    @media (max-width: 830px) {
        &__select-wrapper {
            width: 100%;
        }

        &__select {
            min-width: 100%;
            margin-bottom: 0.5rem;
        }

        &__date-picker {
            min-width: auto;
        }

        &__date-picker-wrapper {
            width: 100%;

            label {
                width: 100%;
            }
        }
    }
}

.react-datepicker {
    display: flex;
}
