.notificationslistitem {
    border: 0px solid rgba(0, 0, 0, 0.125);
    padding: 8px 6px;
    border-bottom: 1px solid $gray-300;

    @include hover-background($gray-100);
    &:hover {
        cursor: default !important;
    }

    /* Card Style */
    background-color: #fff;
    background-clip: border-box;
    border-radius: $border-radius;

    /* Radius */
    td:first-child, th:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius:  $border-radius;
    }
    td:last-child, th:last-child {
        border-bottom-right-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }
    td {
        padding: 22px;
    }
    &__title {
        &__icon {
            padding-right: 24px;
            padding-left: 2px;
            svg {
                margin-top: -3px;
            }
        }
    }

    &__distance {
        span {
            font-size: 14px !important;
        }
    }
}
