.select-order-card {

    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 30px;
    height: 120px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 #e0e6ed;
    background-color: #ffffff;


    &__title {
        font-size: 20px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        margin-bottom: 0;
        margin-left: 24px;
    }

    font-weight: 500;
    color: #3d4858;


    svg * {
        stroke: #bac2ce;
    }

    &:hover {
        // transition: 0.8s linear;
        border: solid 2px rgba(20, 202, 156, 0.5) !important;
        box-shadow: 0 1px 12px 0 #e0e6ed;

        font-weight: 600 !important;
        color: #3d4858 !important;

        text-decoration: none;

        cursor: pointer;

        svg * {
            stroke: #8492a6;
        }
    }


}