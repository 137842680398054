.order-card {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 #e0e6ed;
    background-color: #ffffff;
    padding: 1rem;
    margin-bottom: 0.8rem;
    min-height: 128px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include box-shadow ();

    &--order-avatar {
        .order-avatar-container {
            display: flex;
            align-items: center;
            flex-direction: row;

            a {
                margin-left: 2px;
            }
        }
    }

    &--order-avatar {
        width: 35%;
    }

    &--order-shifts {
        width: 35%;
    }

    &--order-specs {
        width: 20%;
    }

    &--order-btn {
        width: 10%;
    }

    @include media-breakpoint-down(lg){
        flex-direction: column;

        &--order-avatar {
            margin-bottom: 2rem;
            width: 100%;
        }

        &--order-shifts {
            width: 100%;
            margin-bottom: 2rem;
            margin-left: 0.8rem;
        }

        &--order-specs {
            width: 100%;
            margin-bottom: 2rem;
        }

        &--order-btn {
            width: 100%;

            .primary-order-btn {
                width: 100%;
            }

            .order-link {
                width: 100%;
                height: 2rem;
            }
        }
    }

    .toggler {
        cursor: pointer;
        color: $green;
        padding-left: 26px;
        padding-bottom: 6px !important;
        display: block;
        position: relative;
    
        &:hover {
            color: darken( $green, 12% );
        }
    
        &:hover::before {
            border-bottom: 1px solid darken( $green, 12% );
            border-top: 1px solid darken( $green, 12% );
        }
    }
    
    .toggler:before {
        content: "";
        position: absolute;
        height: 1px;
        border-bottom: 1px solid $green;
        border-top: 1px solid $green;
        top: 12px;
        left: 0;
        width: 18px;
    }
}

.order-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    background-color: #e7ebf1;
    height: 2rem;
    margin-bottom: 0.8rem;
    border-radius: 3px;
    font-size: 14px;

    @include media-breakpoint-down(lg) {
        display: none;
        pointer-events: none;
    }
}

.w-35 {
    width: 35%;
}

.w-20 {
    width: 20%;
    padding-left: 10px;
}

.w-10 {
    width: 10%;
}
