.companyshowpage {
    min-height: 90vh;

    &__company-showpage-tabs {
        margin-top: 3.375rem;
    }
    &__company-showpage-tabs .tabs__header {
        border: none;
    }
    &__company-showpage-tabs .tab-panel {
        border-radius: 0.25rem;
        box-shadow: 0 1px 0.25rem 0 #e0e6ed;
        background-color: #ffffff;
        margin-top: -0.25rem;
        padding: 1.25rem;
        position: relative;
        z-index: 0.1;
    }
    &__company-showpage-tabs .tab-panel h4 {
        font-size: 1.375rem;
    }
    &__company-showpage-tabs {
        .tabs__header {
            & > ul span {
                margin: 0 0.5rem 0 0;
                border-radius: 0.25rem 0.25rem 0rem 0rem;
                background-color: #d3dce6;
            }
        }
    }
    &__company-showpage-tabs ul span li {
        padding: 0.875rem 1rem;
        color: #3d4858 !important;
        white-space: nowrap;
    }
    &__company-showpage-tabs ul span li.active {
        background-color: #fff;
        border-bottom: none;
        border-radius: 0.25rem 0.25rem 0rem 0rem;
        z-index: 2;
    }
    &__company-showpage-tabs hr {
        border-top: 0.375rem solid #f1f3f7;
        margin: 0.5rem 0;
    }
    .simplebtn__inline {
        display: inline !important;
    }
    .bg-grey {
        background-color: #f1f3f7;
    }
    .bg-dark {
        background-color: #3d4858;
    }
    &__vikarer-modal .modal-content {
        padding: 0 !important;
    }
    &__vikarer-modal .modal-body {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    &__vikarer-modal .modal-header {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    &__vikarer-modal .modal-dialog {
        min-width: 640px;
        max-width: 1024px;
        margin-left: auto;
    }
    &__vikarer-modal .row-scrollable {
        max-height: 320px;
        overflow-y: auto;
    }
    @include media-breakpoint-down(md) {
        &__company-showpage-tabs ul span li {
            font-size: 12px;
        }
        &__company-showpage-tabs .tab-panel h4 {
            font-size: 16px;
        }
        &__company-showpage-tabs .simplebtn {
            font-size: 12px;
        }
        &__company-showpage-tabs .simplebtn svg {
            width: 24px;
        }
    }

    .company-profile-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;

        .company-profile-avatar {
            margin: 1rem;
        }

        .company-profile-content {
            display: flex;
            flex-direction: column;
            flex: 1;

            &__header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                &--title {
                    margin-top: 1rem;

                    @media only screen and (max-width: 1180px) {
                        text-align: center;
                        margin-bottom: 1rem;
                    }
                }

                &--buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @media only screen and (max-width: 1180px) {
                        margin-bottom: 1rem;
                    }

                    .bmk-menu {
                        width: 250px;
                        margin-right: 0.75rem;
                    }

                    .assing-requester-menu {
                        width: 210px;
                        margin-right: 0.25rem;
                    }
                }
            }

            &__info {
                margin-top: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                &--elements {
                    margin-right: 2rem;
                    display: flex;
                    flex-direction: row;

                    @media only screen and (max-width: 1240px) {
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                    }
                }
            }
        }

        @media only screen and (max-width: 1180px) {
            flex-direction: column;
            align-items: center;

            .company-profile-avatar {
                margin-top: 0;
            }

            .company-profile-content {
                &__header {
                    flex-direction: column;
                    align-items: center;

                    &--buttons {
                        margin-bottom: 1rem;

                        .dots-menu {
                            position: absolute;
                            top: -20px;
                            right: 0;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .company-profile-content {
                &__header {
                    &--buttons {
                        flex-direction: column;

                        .assing-requester-menu {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .company-profile-content {
                &__info {
                    flex-direction: column;
                    justify-content: center;
                    align-self: center;
                    margin-left: 1rem;

                    &--elements {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

.company-avatar {
    border: transparent;
    box-shadow: none;
    background-color: transparent;
}

.two-icon-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px #d3dce6;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    user-select: none;

    svg {
        path,
        circle {
            fill: #8492a6;
        }
    }
}

.department-widget {
    .department-patient-widget--empty {
        margin-top: 1.5rem;
    }
}
