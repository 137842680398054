.onboarding-experience {
    &__header {
        width: 100% !important;
        display: block;
        &__add {
            width: 100%;
            text-align: right;
            position: relative;

            &__btn {
                position: absolute;
                right: 0;
                top: -70px;
                width: 100px;
                margin-left: auto;
                cursor: pointer;
                .icon {
                    margin-left: 12px;
                }
            }
        }
    }

    &__header {
        width: 100% !important;
    }
}
