.recommendations-filter {
    .workersfilter {
        .filter-menu {
            .material-label {
                color: #8f9daf;
            }

            .custom-trigger {
                color: #8f9daf;
            }
        }
    }

    &__resetbtn{

        &--text{
            color: red;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #ed553b;
            margin-left: -10px;
        }

        &:focus{
            box-shadow: none ;
            outline: none ;
        }
  
    }


    .no-bottom-border {
        border-right: solid 1px #d3dce6;
        &:nth-child(5nn + 5) {
            border-right: none;
        }
    }

    .filter-top-border {
        border-right: solid 1px #d3dce6;
        &:nth-child(5nn + 5) {
            border-right: none;
        }
    }

    .filter-border {
        border-right: solid 1px #d3dce6;
        &:nth-child(5nn + 5) {
            border-right: none;
        }
    }

    @include media-breakpoint-down(md) {
        .filter-border {
            border: none;
            border-bottom: solid 1px #d3dce6;
        }

        .filter-top-border {
            border: none;
            border-bottom: solid 1px #d3dce6;
        }

        .no-bottom-border {
            border: none;
            border-bottom: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .filter-top-border {
            border: none;
            border-bottom: solid 1px #d3dce6;
        }

        .no-bottom-border {
            border: none;
            border-bottom: none;
        }
    }
}
.selected-filter {
    .custom-trigger {
        color: #3d4858 !important;
    }
}
