.card-border {
    &__default {
        background-color: #f2b134;
        width: 2px;
    }

    &__accepted {
        background-color: #14ca9c;
        width: 2px;
    }

    &__rejected {
        background-color: #ed553b;
        width: 2px;
    }
}

.avatar-border {
    &__default {
        border: 2px solid #f2b134;
        border-radius: 50%;
        padding: 3px;
    }

    &__accepted {
        border: 2px solid#14CA9C;
        border-radius: 50%;
        padding: 3px;
    }

    &__rejected {
        border: 2px solid#ED553B;
        border-radius: 50%;
        padding: 3px;
    }
}
