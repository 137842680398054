.new-recommendation {
    &__company-container {
        align-items: center;
        justify-content: left;
        display: grid;
        grid-template-columns: 44% 44% 12%;
        background-color: #e0e6ed;
        min-height: 35px;

        &--company-title {
            color: #8492a6;
            margin-left: 10px;
        }

        &--member-title {
            color: #8492a6;
            margin-left: 20px;
        }
    }

    &__company-items {
        align-items: center;
        justify-content: left;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px #e0e6ed;
        border-radius: 3px;
        min-height: 50px;
        margin-top: 12px;
    }

    &__company-card {
        justify-content: left;
        display: grid;
        grid-template-columns: 44% 44% 12%;
        background-color: #ffffff;
        border-radius: 3px;
        min-height: 50px;
        padding-top: 10px;
        padding-bottom: 10px;

        &--trash {
            margin-right: 10px;
            margin-left: auto;
            cursor: pointer;
        }
    }

    &__workers-container {
        width: 100%;
        min-height: 85px;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px #e0e6ed;
        border-radius: 3px;
        margin-top: 12px;
    }

    &__workers-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        min-height: 85px;
        margin-left: 20px;
        margin-top: 10px;
        padding-bottom: 12px;
    }

    &__worker-card {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
    }

    &__worker {
        &--header {
            padding-left: 20px;
            padding-top: 24px;
        }
    }

    &__no-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px #e0e6ed;
        border-radius: 3px;
        height: 140px;
        margin-top: 12px;

        &--text {
            font-size: 14px;
            color: #8492a6;
            line-height: 20px;
            margin-left: -50px;
        }

        &--icon {
            margin-left: -50px;
        }

        &--addnew {
            background: #ffffff;
            box-shadow: 0px 1px 4px #e0e6ed;
            border-radius: 3px;
            height: 46px;
            margin-top: 12px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: $green !important;
            @include fill-svg-color($green);
        }

        &--new-button {
            margin-left: -50px;
        }
    }
}
.disabled {
    opacity: 0.75;
}

.border-line {
    height: 1px;
    background-color: #d3dce6;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 14px;
    margin-bottom: 5px;
}

.minus-margin-title {
    margin-top: -10px;
}

@include media-breakpoint-down(md) {
    .new-recommendation {
        &__no-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ffffff;
            box-shadow: 0px 1px 4px #e0e6ed;
            border-radius: 3px;
            height: 140px;
            margin-top: 12px;

            &--text {
                font-size: 14px;
                color: #8492a6;
                line-height: 20px;
                margin-left: 0px;
            }

            &--icon {
                margin-left: 0px;
            }

            &--new-button {
                margin-left: 0px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .new-recommendation {
        &__no-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ffffff;
            box-shadow: 0px 1px 4px #e0e6ed;
            border-radius: 3px;
            height: 140px;
            margin-top: 12px;

            &--text {
                font-size: 14px;
                color: #8492a6;
                line-height: 20px;
                margin-left: 0px;
            }

            &--icon {
                margin-left: 0px;
            }

            &--new-button {
                margin-left: 0px;
            }
        }
    }
}
