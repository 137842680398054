.workers-availability {
    width: 100%;

    &__wrapper {
        max-width: 100%;
        overflow-x: auto;
        border: 1px solid #e5e7eb;
    }

    &__fixed-width {
        max-height: 611px;
    }

    &__screen-width {
        max-height: 70vh;
        height: 70vh;
    }

    &__empty-state {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;

        svg {
            width: 7.5rem;
            height: 7.5rem;
            color: #9da8b8;
        }
    }

    &__loading {
        .component-spinner {
            top: auto;
            bottom: auto;
        }
    }
}
.mb-10 {
    margin-bottom: 2.5rem;
}
