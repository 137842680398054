.SingleDatePicker {
    .SingleDatePickerInput {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}

.date-picker {
    background-color: #fff;
}
