.order-page {
    &__title {
        &--menus {
            display: flex;
            flex-direction: row;

            .three-dots {
                margin-left: 1rem;
                user-select: none;
 
                svg {
                    g {
                        fill: #8492a6;
                    }
                }
                &--items {
                    border-bottom: 1px solid #f1f3f7;
                    padding: 0.75rem 0 0.75rem 0.75rem;

                    &:last-child {
                        border: none;
                        padding-bottom: 0.5rem;
                    }
                }
            }
        }
    }

    &__content {
        &--left {
            .order-applicants {
                border-radius: 3px;
                box-shadow: 0 1px 4px 0 #e0e6ed;
                background-color: #ffffff;
                margin-bottom: 2rem;

                &__header {
                    padding: 1rem 1rem 1rem 1.5rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    &--button {
                        color: #ffffff;
                        font-size: 14px;
                        font-weight: 600;
                    }

                    &--title {
                        font-size: 18px;
                        font-weight: 600;
                        color: #3d4858;
                        line-height: 1.22;
                        align-self: center;
                        margin: 0;
                    }

                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                        align-items: flex-start;

                        &--button {
                            margin-top: 1rem;
                            width: 150px;
                        }
                        &--title {
                            align-self: flex-start;
                        }
                    }
                }

                &__separator {
                    height: 1px;
                    background-color: #d3dce6;
                    width: 100%;
                }

                &__list {
                    padding: 1.5rem;
                    max-height: 250px;
                    height: 100%;
                    overflow-y: scroll;

                    .ring-btn-holder{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-end;
                        margin-bottom: 1.5rem;

                        &--btn{
                            width: 82px;
                        }
                        @include media-breakpoint-down(md) {
                            justify-content: flex-start;
                        }
                    }

                    &--item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 1.5rem;

                        &-avatar {
                            margin-right: 1.5rem;
                        }

                        &-info {
                            h5 {
                                font-size: 14px;
                                font-weight: 600;
                                color: #3d4858;
                            }
                            span {
                                font-size: 14px;
                                font-weight: 500;
                                color: #8492a6;
                            }
                        }
                    }
                }
            }

            .order-participants {
                border-radius: 3px;
                box-shadow: 0 1px 4px 0 #e0e6ed;
                background-color: #ffffff;
                padding: 1rem 1rem 1rem 1.5rem;
                margin-bottom: 2rem;

                &__header {
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid #d3dce6;
                    padding-bottom: 1rem;

                    &--info {
                        display: flex;
                        justify-content: space-between;

                        h5 {
                            align-self: center;
                            color: #3d4858;
                            font-size: 18px;
                            font-weight: 600;
                            margin: 0;
                        }

                        &-button {
                            align-self: center;
                            cursor: pointer;

                            &-content {
                                svg {
                                    path {
                                        fill: #fff;
                                    }
                                }
                                .icon {
                                    margin-left: 1rem;
                                    margin-bottom: 4px;
                                }
                            }
                        }

                        .participants-dropdown {
                            &--items {
                                border-bottom: 1px solid #f1f3f7;
                                padding: 0.75rem 0 0.75rem 0.75rem;

                                &:last-child {
                                    border: none;
                                    padding-bottom: 0.5rem;
                                }
                            }
                        }
                    }

                    &--boxes {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-top: 1rem;

                        &-elements {
                            display: flex;
                            flex-direction: row;
                            margin-right: 1.5rem;

                            .label {
                                font-size: 16px;
                                font-weight: 500;
                                color: #3d4858;
                            }

                            .indicator {
                                margin-left: 5px;
                                padding-top: 2px;
                                font-size: 14px;
                                font-weight: 600;
                                letter-spacing: -0.5px;

                                &-yellow {
                                    color: #fcaf00;
                                }

                                &-blue {
                                    color: #3a87ff;
                                }

                                &-red {
                                    color: #ed553b;
                                }

                                &-gray {
                                    color: #8492a6;
                                }
                            }
                        }
                    }
                }

                &__list {
                    max-height: 285px;
                    height: 100%;
                    overflow-y: scroll;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-top: 0.5rem;
                    padding-bottom: 1.5rem;
                    border-bottom: 1px solid #d3dce6;

                    &--bussy{
                        border-radius: 16px;
                        background-color: rgba(237, 85, 59, 0.25);
                        padding: 15px;
                        display: flex;
                        align-items: center;
                        margin: 1rem 1rem 0 0;
                        padding: 6px 10px;
                    }

                    &--pills {
                        border-radius: 16px;
                        background-color: #e0e6ed;
                        padding: 15px;
                        display: flex;
                        align-items: center;
                        margin: 1rem 1rem 0 0;
                        padding: 6px 10px;

                        .pill-dots {
                            margin-left: 5px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #3d4858;
                            line-height: 1.5;

                            .yellow {
                                color: #fcaf00;
                            }

                            .blue {
                                color: #3a87ff;
                            }

                            .red {
                                color: #ed553b;
                            }

                            .gray {
                                color: #8492a6;
                            }
                        }
                    }

                    &--button {
                        margin: 1rem 1rem 0 0;
                        padding-top: 5px;
                        cursor: pointer;

                        span {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 1.71;
                            color: #14ca9c;
                        }

                        span:hover{
                            font-size:16px;
                        }

                        span:active{
                            font-size:12px;
                        }
                    }
                }
            }

            .order-shifts {
                margin-bottom: 2rem;
                border-radius: 3px;
                box-shadow: 0 1px 4px 0 #e0e6ed;
                background-color: #ffffff;
                padding: 1.5rem;

                &__title {
                    h5 {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1.22;
                        color: #3d4858;
                        margin: 0;
                    }
                    padding-bottom: 1rem;
                    border-bottom: 1px solid #d3dce6;
                }

                &__description {
                    padding-top: 1rem;
                }

                &__shifts {
                    border-top: 1px solid #F1F3F7;
                    margin-top: 2rem;
                    max-height: 280px;
                    height: 100%;
                    overflow-y: scroll;
                }
            }

            @include media-breakpoint-down(md) {
                .order-shifts {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                .order-participants {
                    &__header {
                        &--info {
                            flex-direction: column;
                            justify-content: flex-start;
                            h5 {
                                align-self: flex-start;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }

        &--right {
            .company-info-card {
                border-radius: 3px;
                box-shadow: 0 1px 4px 0 #e0e6ed;
                background-color: #ffffff;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 1.5rem;
                margin-bottom: 2rem;
                word-break: break-all;

                &__avatar {
                    margin-right: 1rem;
                }

                &__info {
                    h5 {
                        font-size: 18px;
                        font-weight: 500;
                        color: #3d4858;
                    }

                    a {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
            // end - company-info-card

            .order-info-card {
                border-radius: 3px;
                box-shadow: 0 1px 4px 0 #e0e6ed;
                background-color: #ffffff;
                padding: 2rem;

                &--element {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 2rem;

                    &-icon {
                        margin-right: 2rem;
                        width: 18px;
                        height: 18px;
                        overflow: visible;
                    }

                    &-info {
                        display: flex;
                        flex-direction: column;

                        .info-heading {
                            font-size: 14px;
                            font-weight: 500;
                            color: #8492a6;
                        }

                        .info-subheading {
                            font-size: 16px;
                            font-weight: 500;
                            color: #3d4858;
                        }

                        a {
                            font-size: 14px;
                            font-weight: 500;
                            color: #14ca9c;
                            margin-top: 0.25rem;
                        }
                    }

                    &:last-child() {
                        margin-bottom: 0;
                    }
                }
            }
            // end - order-info-card

            //media
            @include media-breakpoint-down(md) {
                .order-info-card {
                    margin-bottom: 2rem;
                }
            }
            @include media-breakpoint-down(sm) {
                .order-info-card {
                    margin-bottom: 2rem;

                    &--element {
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        &-icon {
                            margin: 0;
                            margin-bottom: 1rem;
                        }
                    }
                }

                .company-info-card {
                    flex-direction: column;
                    text-align: center;
                    &__avatar {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
    }

    @include media-breakpoint-down(md) {
        &__title {
            &--menus {
                margin-top: 2rem;

                .three-dots {
                    position: absolute;
                    top: 0;
                    right: 16px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__title {
            &--menus {
                flex-direction: column;
                padding-left: 10px;
                align-self: flex-start;
            }
        }
    }

    // svg edit
    svg[title="sun18"] g, 
    svg[title="fog18"] path, 
    svg[title="time18"] path:first-child(), 
    svg[title="night18"] path,
    svg[title="undo18"] g, 
    svg[title="circle18"] g,
    svg[title="refresh18"] g
    {
        stroke: #8492a6;
    }

    svg[title="sun18"] path, svg[title="fog18"] path{
        stroke-width: 1.6;
    }

    svg[title="time18"] path:last-child(){
        fill: #8492a6;
    }
}

.order-view-filter-page {
    // dropdown overwrites
    .coredropdown__menu {
        right: unset !important;
        left: 0;
        margin-top: 5px;
        min-width: 100%;

        .coredropdown__menu__item {
            padding: 0.5rem 1rem;
        }
    }
    // dropdown for sort
    // .drop-sort{}

    // autocomplete for companies
    .companies-autocomplete {
        width: 100%;
    }
}

.list-style-violation {
    padding: 1rem;
    padding-left: 1.5rem;
}




