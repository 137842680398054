.date-picker {
    padding-right: 1px;
    padding-left: 1px;
    border: none;
    .SingleDatePicker {
        padding-right: 1px;
        border: solid 1px #d3dce6;
        .SingleDatePickerInput {
            height: 56px;
        }
    }

    &__label {
        font-size: 12px;
        padding: 0 0 0.5rem 0;
    }
}
