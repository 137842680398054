.select-component-container {
    border-bottom: solid 1px #d3dce6;
    border-right: solid 1px #d3dce6;
    position: relative;

    .material-label {
        color: #3d4858;
    }

    .material-input {
        border: none;
    }

    .select-menu-container {
        cursor: pointer;
        margin-top: 10px;
        position: absolute;
        max-height: 400px;
        padding: 1rem 0;
        overflow-y: scroll;
        border: none;
        top: 100%;
        left: auto;
        z-index: 1000;
        font-size: 1rem;
        color: #3d4858;
        text-align: left;
        background-color: #fff;
        background-clip: padding-box;
        box-shadow: 0 0.6rem 1.2rem rgba(52, 58, 64, 0.095);
    }
}