.editordershiftsmodal {
    &__modal {
        .modal-dialog {
            max-width: 545px !important;
            min-width: 300px !important;
        }
        .modal-header {
            position: relative;
            &:after {
                position: absolute;
                top: 60px;
                left: -8px;
                content: '';
                border: solid 0.7px #e0e6ed;
                width: 103.2%;
            }
        }
        .modal-footer {
            .btn-success {
                padding: 9px 23px !important;
            }
        }
        &__alert {
            h5 {
                font-size: 16px;
            }

            p {
                font-size: 14px;
            }

            .link {
                color: #274883;
                font-size: 15px;
                @include stroke-svg-color(#274883);

                .icon {
                    transition: all 0.2s ease-in-out;
                    margin-top: -5px;
                    margin-left: 8px;
                }

                &:hover {
                    .icon {
                        transition: all 0.2s ease-in-out;
                        transform: translate(3px);
                    }
                }
            }

            .link:hover {
                color: #274883;
            }
        }
    }

    &__form {
        &____timepickers {
            flex: 1;
        }
    }
}
