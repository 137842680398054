.absencerequestmodal {
    &__modal {
        .modal-dialog {
            max-width: 545px !important;
            min-width: 300px !important;
        }

        .modal-header {
            position: relative;
        }
    }
}
