.patientinfo-page {
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 3fr 2fr;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &__left {
        grid-area: left;
    }

    &__right {
        grid-area: right;
    }

    @include media-breakpoint-down(md) {
        padding-left: 0;
        grid-template-areas: "left" "right";
        grid-template-columns: 1fr;
    }
}

// generic patient group styles
.patient-group {
    padding: 0.5rem;
    margin-bottom: 1rem;

    &--header {
        padding-bottom: 1.2rem;
        padding-left: 2px;
        color: #3d4858;
        line-height: 22px;
        font-weight: bold;
    }
}

.patient-type-group {
    // overwrite
    .core-tag-pannel {
        margin-top: 1rem;
    }
}

.patient-details-group {
    .core-linked-inputs {
        margin-top: 1rem;
    }
}

.patient-outrage-group {
    position: relative;
    .ruller {
        margin: 0;
        margin-top: -35px;
        padding: 0;
        padding-left: 12px;
        padding-right: 24px;
        cursor: normal;
        user-select: none;
        position: absolute;
        width: 100%;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
            padding-top: 35px;
            color: #9aa6b8;
            // -1 from handles default
            z-index: 9998 !important;
        }

        li.active {
            color: #3d4858;
        }
    }

    .patient-group--selft-harm {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        padding-left: 36px;

        .linked-input-checkbox:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .patient-group--textbox {
        margin-top: 2rem;
    }

    .patient-group--verbal {
        margin-top: 1rem;
    }
}

.patient-hygene-group {
    display: flex;
    flex-direction: column;

    .linked-input {
        margin-bottom: 1rem;
    }

    .linked-input:last-child {
        margin-bottom: 0;
    }
}

.core-linked-groups {
    display: flex;
    flex-direction: row;
    align-items: center;

    .linked-chain {
        display: block;
        margin: 0 1rem;
        user-select: none;
    }

    .linked-input {
        width: 100%;
    }

    .linked-input-50 {
        width: 50%;
    }
}
