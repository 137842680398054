.departmentinfo-page {
    max-width: 800px;
    .inputs-container {
        padding-left: 1.5rem;
        padding-right: 3.5rem;
    }

    .full-input {
        width: 100%;
        margin-bottom: 1rem;
    }

    .half-input {
        width: 49%;
        margin-bottom: 1rem;
    }

    .quarter-input {
        width: 25%;
        margin-bottom: 1rem;
        @include media-breakpoint-down(md) {
            width: 49%;
            margin-left: 1rem !important;
        }
    }

    .half-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .quarter-inputs {
        display: flex;
        flex-direction: row;
    }
}

