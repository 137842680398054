.worker-onboarding-checklist-panel {
    margin-bottom: 2rem;
    padding: 0 1rem;
    border-radius: 3px !important;

    h5 {
        font-weight: 600;
        color: #3d4858;
    }

    p {
        color: #647284;
    }
}
