.departmentsdropdown {
    // position: relative;
    // height: 32px;
    // outline: none;

    &__btn {
        pointer-events: all;
        height: 32px;
        width: 205px;
        outline: none;

        &--arrow {
            width: 10px;
            height: 6px;
        }

        &:focus {
            outline: none;

            // &* {
            //     outline: none;
            // }
        }

        &__description {
            span {
                padding: 0;
                margin: 0;
                line-height: 16px;
            }

            &__position {
                font-size: 14px;
                line-height: 14px;
            }
        }
    }

    &__content {
        &__title {
            font-size: 10px;
        }

        &__departments {
            list-style-type: none;
            flex: 1;
            width: 100%;
            margin: 0;
            padding: 0;

            li {
                flex: 1;
                width: 100%;
                transition: color 0.2s ease-in-out;

                &.active {
                    color: $green;
                }

                &:hover {
                    color: $green;
                    transition: color 0.2s ease-in-out;
                }
            }
        }

        &__new {
            width: 100%;
            border-top: 1px solid $gray-300;
            @include fill-svg-color($green);

            span {
                transition: color 0.2s ease-in-out;
            }

            &:hover {
                span {
                    color: $green !important;
                    transition: color 0.2s ease-in-out;
                }
            }
        }
    }



    .dropdown-menu {
        min-width: 262px !important;
        margin-top: -5px;
    }
}

.dropdownmenu__btn {
    outline: none;

    &:focus {
        outline: none;
    }
}


.txt-10{
    font-size: 12px !important;
}