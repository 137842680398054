.worker-availability-body {
    & > tr:first-child {
        box-shadow: inset 0px 16px 16px -18px #e5e7eb;
    }

    &__row {
        td:nth-child(n + 3) {
            border-left: 1px solid #e5e7eb;
        }

        td:nth-child(2) {
            box-shadow: inset 14px 0px 14px -18px #e5e7eb;
        }
    }

    &__data {
        &__name {
            min-width: 19.688rem;
            max-width: 19.688rem;
            position: sticky;
            background-color: white;
            box-shadow: inset -1px 0 #e5e7eb;
            left: 0;

            &:hover {
                cursor: pointer;
            }
        }

        &__status {
            padding: 1rem 0.5rem !important;
            min-width: 7rem;
            max-width: 7rem;
            height: 5.531rem;

            div {
                border-radius: 0.25rem;
                width: 100%;
                height: 3.5rem;
            }

            &--available {
                background: #61e6c0;
            }

            &--booked {
                background: #d4695e;
            }

            &--in-shift {
                background: #fdba74;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__data__name {
            width: 100%;
            max-width: 0;
            min-width: 10rem;
            word-wrap: break-word;
            overflow: auto;

            .avatar {
                display: none;
            }
        }
    }
}
