.worker-card-heading {
    flex-grow: 2;
    button {
        font-weight: 600;
        font-size: 18px;
        color: #3d4858;
    }

    h6 {
        font-weight: 500;
        font-size: 16px;
        color: #8492a6;
    }

    @include media-breakpoint-down(md) {
        &__btn-group {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        &__interviewer {
            display: none;
        }

        &__btn-group {
            display: none;
        }

        button {
            font-size: 16px;
        }

        h6 {
            font-size: 14px;
        }
    }
}
