.filter-worker-card {
    #btn-stages_menu_cards > svg {
        margin-right: 5px;
    }
}

.filter-worker-list {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
}
