.gender-header{
    font-size: 12px;
    line-height:26px ;
    color: #8492A6;
    margin-bottom: -2px;
}

.personal-information{
    &--position{
        margin-left: -30px;
    }
}

.description-header{
    font-size: 18px;
    color:#3D4858;
    line-height: 20px;
    margin-top: 2rem;
} 