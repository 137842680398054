.courses-skills {
    &--title-size {
        margin-top: 40px;
        margin-bottom: 24px;
        font-size: 20px;
    }

    &__searchbar {
        padding-top: 22px;
        padding-bottom: 22px;
        border-bottom: 1px solid $gray-300;
    }

    &__results {
        padding: 22px 21px;

        &__item {
            background-color: $gray-600;
            padding: 4px 6px;
            margin-right: 10px;
            margin-bottom: 8px;
            color: white;
            border-radius: $border-radius;
            cursor: pointer;

            &:hover {
                .icon {
                    path {
                        fill: #FFF;
                    }
                }
            }

            .icon {
                margin-top: -3px;
                transform: rotate(45deg);
                margin-left: 7px;

                path {
                    fill: $gray-500;
                }
            }
        }
    }

    &__courses {
        position: relative;
    }

    &__skills {
        position: relative;
    }
}
