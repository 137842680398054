.worker-profile-sidebar {
    &__care {
        background: #ffffff;
        margin: 1rem 0;
        padding: 0.5rem;
        border-radius: 3px;

        &__title {
            color: #465263 !important;
        }
    }

    &__title {
        margin-top: 0.5rem;
    }

    &__location {
        div {
            padding-left: 0 !important;
            margin-top: 0 !important;
        }
    }

    &__preference {
        gap: 0.5rem;
        display: flex;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 0 !important;
    }
}

.fit-content {
    width: fit-content;
}
