.schedule-dropdown-container {
    .coredropdown {
        &__menu {
            left: unset;
            right: 0;
            margin-top: 20px;
            overflow-y: unset;

            &__item {
                align-items: unset;
                margin-left: 0.5rem !important;
            }
        }
    }

    .menu-label {
        font-size: 10px;
        line-height: 11.72px;
    }

    .custom-item {
        font-size: 16px;
        line-height: 18.75px;
    }
    .menu-item-line {
        background-color: #d3dce6;
        height: 1px;
        margin-top: 14px;
        margin-bottom: -4px;
    }
}

.schedule-buttons-container {
    border-radius: 0.25rem;
    background: #14ca9c linear-gradient(180deg, #35d0aa, #14ca9c) repeat-x;
    border-color: #14ca9c;

    .dropdown {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.readonly-stage{
    background-color: white;
    min-height: 57px;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    font-size: 16px;

    &__type{
        font-size: 16px;
        line-height: 18.75px;
        margin-top:20px;
        margin-left:10px
    }
}