.order-dropdown {
    position: relative;
    outline: none;

    &__controlls {
        margin-bottom: 1rem;
        user-select: none;
        cursor: pointer;
        display: inline-block;
        margin-right: 1rem;
        outline: none;

        svg {
            width: 16px;
            height: 16px;
            //fill: #8492a6;
        }

        span {
            font-size: 14px;
            font-weight: 500;
            color: #3d4858;
        }
    }

    &__menu {
        position: absolute;
        top: 100%;
        right: 0;
        left: auto;
        z-index: 1000;
        max-height: 480px;
        height: auto;
        width: 480px;
        border-radius: 3px;
        box-shadow: 0 4px 8px 2px rgba(61, 72, 88, 0.15);
        background-color: #ffffff;
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;

        @include media-breakpoint-down(md) {
            right: auto;
            left: auto;
            width: 275px !important;
        }


        //temporary
        overflow-y: scroll;

        &--separator {
            height: 1px;
            background-color: #d3dce6;
        }

        &--header {
            padding-left: 1rem;
            padding-bottom: 1rem;

            .dropdown-title {
                font-size: 16px;
                font-weight: 600;
                color: #465263;
            }
        }

        &--list {
            padding: 1rem;
            padding-bottom: 0;

            .order-dropdown-item {
                //activity log variant
                &--variant-text {
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid #d3dce6;
                    margin-bottom: 1rem;
                    padding-bottom: 0.5rem;
                    cursor: pointer;

                    &__date {
                        font-size: 14px;
                        font-weight: 600;
                        color: #8492a6;
                        display: flex;
                        flex-direction: column;
                        margin-right: 2rem;
                    }

                    &__description {
                        font-size: 14px;
                        font-weight: 500;
                        color: #3d4858;
                    }

                    &:last-child() {
                        border: none;
                        margin-bottom: 0;
                    }
                }

                // order view variant
                &--variant-image {
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid #d3dce6;
                    padding-bottom: 0.5rem;
                    margin-bottom: 1rem;
                    cursor: pointer;

                    &__avatar {
                        align-self: center;
                        margin-right: 2rem;
                    }

                    &__content {
                        .user-full-name {
                            font-size: 14px;
                            font-weight: 600;
                            color: #3d4858;
                        }

                        .user-date-time {
                            font-size: 14px;
                            font-weight: 500;
                            color: #8492a6;
                        }
                    }

                    &:last-child() {
                        border: none;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
