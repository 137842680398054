.worker-introduction-conversation {
    &__description {
        height: 100%;

        &--readOnly {
            border: none;
        }

        & > div {
            height: 90%;
        }
    }

    &__empty-state {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 60px;
            height: 60px;
            color: #9da8b8;
        }
    }
}
