.worker-card-info {
    justify-content: space-between;
    padding-top: 1.5rem;

    &__cv {
        width: 100%;
        max-width: 280px;
    }

    &__buttons--mobile {
        display: none;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 0 1.5rem;

        div {
            padding-bottom: 1rem;
        }
    }

    @include media-breakpoint-down(md) {
        &__cv {
            display: none;
        }

        &__buttons--mobile {
            display: block;
            padding-top: 4px;
            width: 100%;

            & > div {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 1rem;

        &__cv {
            display: none;
        }

        &__buttons--mobile {
            display: block;
            padding-top: 4px;
            width: 100%;

            & > div {
                width: 100%;
            }
        }
    }
}
