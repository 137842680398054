.worker-card-image {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 166px;
    min-width: 166px;
    background-color: #d3dce6;
    border-top-left-radius: 0.25rem;

    &__video {
        all: unset;
        padding: 0;
        margin: 0;
        background-color: transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        &:focus {
            outline: none;
        }
    }

    &__stage-details--mobile {
        display: none;
    }

    &__status {
        display: flex;
        flex-direction: row;
        background-color: transparent;
        position: absolute;
        bottom: 0;
        right: 0;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
            0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 25px;
        margin-right: 4px;
        margin-bottom: 4px;
        z-index: 1;

        &--dot {
            height: 6px;
            width: 6px;
            background-color: #14ca9c;
            border-radius: 50%;
            display: inline-block;
            margin-left: 7px;
            margin-bottom: 7px;
            margin-top: 7px;
            margin-right: 4px;
        }

        &--text {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #3d4858;
            margin-right: 7px;
        }
    }

    img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        height: 200px;
        min-height: 200px;
        border-top-right-radius: 0.25rem;

        &:after {
            box-shadow: inset 0 -100px 200px -100px #000000;
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
        }

        &__stage-details {
            &--mobile {
                z-index: 1;
                position: absolute;
                display: flex;
                align-items: center;
                bottom: 13px;
                left: 22px;

                p {
                    color: #ffffff;
                }
            }

            &--video {
                bottom: 7px;
                left: 45px;
            }
        }
    }
}
