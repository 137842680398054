//margin-top: 0.5rem;

.rangeslider{
    user-select: none !important;
    height: 0.5rem !important;
    &__labels{
        margin-top: 0.5rem;
    }
    &__handle{
            border: none !important;
            box-shadow: 0px 1px 4px #E0E6ED !important;
            &::after {
                content: none !important;
            }
            outline: none !important;
            z-index: 9999 !important;
    }
    &__fill {
        background-color: #14CA9C !important;
        box-shadow: 0px 1px 4px #E0E6ED !important;
        border-radius: 10px;
    }
    box-shadow: none !important;
    background: #9AA6B8 !important;
}
