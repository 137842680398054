.recommend-uworker{
    &__container{
        border: 1px solid #D3DCE6;
        border-radius: 3px;
        margin-top: 10px;
        height: 217px;
        overflow-y: scroll;
    }

    &__label{
        margin-left: 14px;
        font-size: 12px;
        margin-top: 10xpx;
        color:#8492A6;
    }  
}  
    
.no-scroll{
    overflow-y: unset !important;
}