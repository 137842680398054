.timesheetpage {
    position: relative;

    .card {
        padding: 10px 24px;
    }

    &__header-buttons {
        width: 100%;
        text-align: right;
        position: relative;

        &__btn {
            margin-top: -120px;
        }
    }

    &__body {
        margin-top: 34px;
        position: relative;
    }

    &__noresult {
        height: 100%;
        min-height: 352px;
    }

    &_header {
        background: $white;
        @include box-shadow ();
        border-radius: 3px;
    }

    &__absenceleave {
        .modal-dialog {
            min-width: unset;
            max-width: 545px;

            .modal-header {
                margin-left: -8px;
                margin-right: -8px;
                padding-left: 24px;
                padding-right: 24px;
                border-bottom: 1px solid $gray-300;
            }
        }

        &__content {
            &__title {
                font-size: 12px;
            }
        }
    }

    .timesheetlistitem__checkmark {
        min-width: auto;
    }
}
