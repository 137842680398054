.invite-team-member-header {
    font-weight: 600;
    color: #4f5c6e;
    margin-top: -10px;
}

.add_new_department {
    &__modal {
        .modal-dialog {
            max-width: 820px !important;
            min-width: 300px !important;
        }

        .modal-header {
            position: relative;
        }
    }

    &__step {
        font-size: 14px;
        color: #8492a6;
    }
}

.footer-border {
    border-top: 1px solid #F1F3F7;
}

.name-position {
    margin-left: -170px;
}

.email-position {
  margin-left: -105px;
}

.member-item-container{
    overflow-y: auto;
    max-height: 320px;
    background-color: #f1f3f7;
    border-radius: 5px;
}

.break-line{
    background-color:#d3dce6 ;
    height: 1px;
    margin:0 20px;
}

@media only screen and (max-width: 992px) {
    .name-position {
        margin-left: 0;
    }
    .email-position {
      margin-left: 0;
  }
}
