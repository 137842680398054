.workerspage {
    &__cardview {
        min-height: 30vh;
    }
}

.worker-interview-modal {
    .modal-dialog {
        max-width: 800px !important;
        min-width: 360px !important;
    }

    .date-picker {
        z-index: 3;
    }

    .select-wrapper {
        z-index: 2;
    }
}

.border-none {
    .select-wrapper {
        border: none;
    }
}

.border-gray-1 {
    border-bottom: 1px solid #dee2e6;
}

