.worker-card-attachments {
    border: 1px solid #e0e6ed;
    background-color: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 1rem;
}
